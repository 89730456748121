import request from "./request";
// 获取FAQ
export const getFAQList = (data) => {
  return request({
    url: "/v1/manager/faq/list",
    method: "post",
    data: data,
  });
};
// 获取单个FAQ列表
export const getFAQById = (data) => {
  return request({
    url: "/v1/manager/faq/get",
    method: "post",
    data: data,
  });
};
