import axios from 'axios'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'
import { ElMessage } from 'element-plus'
import store from '@/store'
// import { diffTokenTime } from '../utils/auth'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 10000
})
service.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token')
        if (token) {
            if (service.interceptors.request.status === 401) {
                return Promise.reject(new Error('token failed'))
            }
            // config.headers.Authorization = token
        }
        return config
    },
    (error) => {
        return Promise.reject(new Error(error))
    }
)
service.interceptors.response.use(
    (response) => {
        if (response.status === 401) {
            return Promise.reject(new Error('token failed'))
        } else if (response.data.code === 0) {
            return response.data.payload
        } else {
            alert(response.data.msg)
            return Promise.reject(new Error(response.data.msg))
        }
    },
    (error) => {
        error.response && alert(error.response.data)
        return Promise.reject(new Error(error.response.data))
    }
)
export default service
